import { apiSlice } from "./apiSlice"
import { apiRoutes } from "../../config/api"
import CryptoJS from 'crypto-js';

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: apiRoutes.LOGIN,
                method: 'POST',
                body: { username: credentials?.username, password: CryptoJS.AES.encrypt(credentials?.password, process.env.REACT_APP_SECRET_KEY || "7523456789abcdef0123456789abcdef0123456789abcdef0123456789abcdef").toString() }
            })
        }),
        getAuth: builder.mutation({
            query: credentials => ({
                    url: apiRoutes.GET_AUTH,
                    method: 'get',
        })
        }),
        getDMAuth: builder.mutation({
            query: credentials => ({
                    url: apiRoutes.GET_DM_AUTHENTICATED,
                    method: 'get',
        })
        }),
    })
})

export const {
    useLoginMutation,
    useGetAuthMutation,
    useGetDMAuthMutation
} = authApiSlice