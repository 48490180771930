import { config } from './index'
import { BaseUrls } from '../types';

const baseUrls: BaseUrls = {
    local: "http://localhost:8080",
    dev: process.env.REACT_APP_DOMAIN!,
    qa: "https://qacloudstation.lifecycles.io",
    demo: "https://demo.lifecycles.io",
    prod: "https://devicemanager.lifecycles.io",
}
console.log(process.env.REACT_APP_DEVICE_MANAGER_TYPE, "env variables", process.env.REACT_APP_DOMAIN)
export const baseURL = baseUrls[config.ENV];

export enum apiRoutes {
    LOGIN = '/api/v1/login',
    GETALLPROCESS = '/api/v1/station/processList',
    REFRESH_TOKEN = '/api/v1/refresh',
    GETALLSITES = '/api/v1/site',
    GETALLSITEBYUSER = '/api/v1/userSites',
    GETALLDEVICEBYZONE = '/api/v1/deviceZone',
    PLAY_EXECUTEPROCESS = '/api/v1/process/execute',
    CANCEL_SITEBUTTON = 'api/v1/process/stop',
    CREATE_STATE = '/api/v1/station/processStates',
    USER_DEVICE = '/api/v1/userDevice',
    START_PROCESS = '/api/v1/process/start',
    DEVICE_STATUS = '/api/v1/deviceStatus',
    DEVICE_STATUS_BY_MAC = '/api/v1/getdeviceStatus',
    RESUME_PROCESS = '/api/v1/process/resume',
    PAUSE_PROCESS = '/api/v1/process/pauseState',
    TAG_DATA = '/api/v1/tag',
    GET_AUTH = '/api/v1/getAuthenticated',
    AUDITS='/api/v1/audits',
    PREVIOUS_PROCESS='/api/v1/states/stopped',
    ZONE_BY_SITE='/api/v1/zone',
    GET_DM_AUTHENTICATED='/api/v1/authenticateDM'
}
